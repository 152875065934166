/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

// reactstrap components
import { Row, Container, Col } from "reactstrap";

function LimehillsFooter() {

  const scrollToContact = () => {
    scroller.scrollTo('contact', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  const scrollToAbout = () => {
    scroller.scrollTo('about', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <Col lg="3" sm="12" className="text-center footer-logo">
            <img src={require("assets/img/limehills/limehills-logo.png")} alt=""></img>
            <ul>
              <li><Link to="/" target="_blank"><i className="fa fa-facebook" onClick={(e) => { e.preventDefault(); window.open('https://facebook.com/limehillsconsulting'); }}></i></Link></li>
              <li><Link to="/" target="_blank"><i className="fa fa-twitter" onClick={(e) => { e.preventDefault(); window.open('https://limehills.net/'); }}></i></Link></li>
              <li><Link to="/" target="_blank"><i className="fa fa-linkedin" onClick={(e) => { e.preventDefault(); window.open('https://linkedin.com/company/limehills/'); }}></i></Link></li>
              <li><Link to="/" target="_blank"><i className="fa fa-github" onClick={(e) => { e.preventDefault(); window.open('https://limehills.net/'); }}></i></Link></li>
            </ul>
          </Col>
          <Col>
            <h5>Links</h5>
            <ul>
              <li><Link to="/" target="_blank" onClick={(e) => { e.preventDefault(); window.open('https://limehills.atlassian.net/servicedesk/customer/portal/2'); }}>Support</Link></li>
            </ul>
          </Col>
          <Col>
            <h5>Legal</h5>
            <ul>
              <li><Link to={"/terms"}>Terms</Link></li>
              <li><Link to={"/privacy"}>Privacy</Link></li>
            </ul>
          </Col>
          <Col>
            <h5>Social</h5>
            <ul>
              <li><Link to="/" target="_blank" onClick={(e) => { e.preventDefault(); window.open('https://www.facebook.com/limehillsconsulting'); }}>Facebook</Link></li>
              <li><Link to="/" target="_blank" onClick={(e) => { e.preventDefault(); window.open('https://www.linkedin.com/company/limehills/'); }}>LinkedIn</Link></li>
            </ul>
          </Col>
          <Col>
            <h5>Company</h5>
            <ul>
              <li><Link to="/contact" onClick={(e) => scrollToContact()}>Contact Us</Link></li>
              <li><Link to="/about" onClick={(e) => scrollToAbout()}>About us</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}


export default LimehillsFooter;