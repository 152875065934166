/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import MessengerCustomerChat from 'react-messenger-customer-chat';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
// pages
import LandingPage from "views/limehills/LandingPage.js";
import PrivacyPage from "views/limehills/PrivacyPage.js";
import TermsPage from "views/limehills/TermsPage.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <MessengerCustomerChat
      pageId="113186297187115"
      appId="346960426350552"
      theme_color="#0747A6"/>
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => <LandingPage {...props} />}
      />
      <Route
      path="/privacy"
      exact
      render={(props) => <PrivacyPage {...props} />}
      />
       <Route
      path="/terms"
      exact
      render={(props) => <TermsPage {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

