/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Element} from "react-scroll";

// core components
import LimehillsNavbar from "components/Navbars/LimehillsNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import LimehillsFooter from "components/Footers/LimehillsFooter.js";
import LimehillsIntro from "components/limehills/LimehillsIntro.js";
import LimehillsServices from "components/limehills/LimehillsServices.js";
import LimehillsCallToAction from "components/limehills/LimehillsCallToAction.js";
import LimehillsContact from "components/limehills/LimehillsContact.js";
import LimehillsFlow from "components/limehills/LimehillsFlow.js";

function LandingPage() {
  return (
    <>
      <LimehillsNavbar pageType="home" />
      <LandingPageHeader />
      <div className="main">
        <Element name="about">
          <LimehillsIntro />
        </Element>
        <Element name="flow">
          <LimehillsFlow />
        </Element>
        <Element name="services">
          <LimehillsServices />
        </Element>
        {/* <Element name="works">
          <LimehillsWorks />
        </Element> */}
        <LimehillsCallToAction />
        <Element name="contact" className="contact">
          <LimehillsContact />
        </Element>
        {/* <Element name="modal" className="author">
          <LimehillsModal />
        </Element> */}
      </div>
      <LimehillsFooter />
    </>
  );
}

export default LandingPage;