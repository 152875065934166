import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";


function LimehillsFlow() {
    return (
        <div className="section text-center limehills-flow">
             <Col className="text-center">
                    <h1 className="title">How we work</h1>
                </Col>
            <div style={{ backgroundImage: "url(" + require("assets/img/limehills/main.png") + ")" }} className="flow-image">
                <Container>
                <Row>
                    <Col xs="4">
                        <Card className="card-plain gathering">
                            <CardBody>
                                <div className="author">
                                    <CardTitle tag="h4">Gathering</CardTitle>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={{ size: 4, offset: 4 }}>
                        <Card className="card-plain">
                            <CardBody>
                                <div className="author implementation">
                                    <CardTitle tag="h4">Implementation</CardTitle>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 4, offset: 4 }}>
                        <Card className="card-plain">
                        <CardBody>
                                <div className="author analysis">
                                    <CardTitle tag="h4">Analysis</CardTitle>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
    )
}
export default LimehillsFlow;
