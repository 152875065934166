import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";


function LimehillsServices(props) {
    const {
        className
    } = props;

    const [content, setContent] = useState({ title: '', description: '' });

    const handleOnClick = (service) => {

        switch (service) {
            case 'engineering':
                setContent({
                    title: 'Engineering', description: `
                    We specialise in management of the process of transitioning your services to the Cloud. We make sure that through the process of migration making the resulting solution is best suited for real business needs and enables cost savings. We help to take full advantage of the capabilities offered by cloud solution.`
                });
                break;
            case 'consulting':
                setContent({
                    title: 'Consulting', description: `
                Software consulting services are aimed at maximizing the ROI of a software development initiative through effective planning, execution and management of new software development project, or setting to rights an ongoing project. Providing all-round software consulting, ScienceSoft helps clients from 26 industries keep end-to-end software development fast and economically sound and deliver high-quality software solutions.
                    ` });
                break;
            case 'development':
                setContent({
                    title: 'Development', description: `
                     From the most basic promotional sites to highly complex web applications, we have built them all using well proven web2.0 technologies. 
                     We mainly service ISV's and entertainment agencies around the globe assuring you that we have experience in converting complex wishlists into workable systems, all SEO an SMO optimized combined with a good level of user experiences.
We have experience in the following technologies and frameworks:
ReactJS, CodeIgniter, CakePHP, MySQL, PostgreSQL, MongoDB, Facebook Graph APIm Google API, etc...
                    ` });
                break;
            case 'automation':
                setContent({
                    title: 'Automation', description: `
                Automation, Vital for DevOps
DevOps is no doubt a viral technology trend across firms, globally. And, if one has to see the true potential of DevOps, then Automation is vital.

There are four paths that basically define organizations’ success through DevOps implementation: Steer inefficiency, Develop/Test, Deploy and Operate.

The first two phases transform the way firms operate from process-based to product-based and finally towards optimized development process.
Then comes to the third and fourth stages, i.e. automated deployment and effective operation with Automation as the driving force, accelerating the overall development process quickly and smartly.
DevOps automated deployment involves ‘process automation’ that takes the organizational DevOps transition to the next level ensuring enhanced transparency, quick releases and easing further deployment.
                    ` });
                break;
            case 'testing':
                setContent({
                    title: 'Testing', description: `
                The reliability and quality of software products is essential to the success of any business. With a wealth of experience and an industry-leading suite of tools at our disposal (Load Runner, Selenium, JMeter), Adexin’s testing experts excel at identifying bugs before they derail your schedule or budget. Our dedicated offshore testing services include a wide range of testing methods and tools to ensure that we deliver the highest quality.
                    ` });
                break;
            case 'security':
                setContent({
                    title: 'Security', description: `
                The reliability and quality of software products is essential to the success of any business. With a wealth of experience and an industry-leading suite of tools at our disposal (Load Runner, Selenium, JMeter), Adexin’s testing experts excel at identifying bugs before they derail your schedule or budget. Our dedicated offshore testing services include a wide range of testing methods and tools to ensure that we deliver the highest quality.` });
                break;
            default:
                break;
        }
        setModal(true);
    };

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        setModal(modal);

    }, [modal]);
    return (
        <div className="section text-center limehills-services">
            <Container>
                <Col className="text-center">
                    <h1 className="title">Services</h1>
                </Col>
                <Row>
                    <Col md="6" lg="4">
                        <Card className="card-profile" onClick={() => handleOnClick('development')}>
                            <CardBody>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-spaceship" />
                                    </div>
                                </Link>
                                <Link to="/" onClick={(e) => { e.preventDefault(); }}>
                                    <div className="author">
                                        <CardTitle tag="h4">Development</CardTitle>
                                    </div>
                                </Link>
                                <p className="card-description text-left">

                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card className="card-profile" onClick={() => handleOnClick('engineering')}>
                            <CardBody>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-settings" />
                                    </div>
                                </Link>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Engineering</CardTitle>
                                    </div>
                                </Link>
                                <p className="card-description text-left">
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card className="card-profile" onClick={() => handleOnClick('consulting')}>
                            <CardBody>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-tile-56" />
                                    </div>
                                </Link>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Consulting</CardTitle>
                                    </div>
                                </Link>
                                <p className="card-description text-left">
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card className="card-profile" onClick={() => handleOnClick('automation')}>
                            <CardBody>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-user-run" />
                                    </div>
                                </Link>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Automation</CardTitle>
                                    </div>
                                </Link>
                                <p className="card-description text-left">

                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card className="card-profile" onClick={() => handleOnClick('testing')}>
                            <CardBody>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-ruler-pencil" />
                                    </div>
                                </Link>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Testing</CardTitle>
                                    </div>
                                </Link>
                                <p className="card-description text-left">

                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" lg="4">
                        <Card className="card-profile" onClick={() => handleOnClick('security')}>
                            <CardBody>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="icon icon-primary">
                                        <i className="nc-icon nc-lock-circle-open" />
                                    </div>
                                </Link>
                                <Link to="/" onClick={(e) => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Security</CardTitle>
                                    </div>
                                </Link>
                                <p className="card-description text-left">

                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Modal isOpen={modal} toggle={toggle} className={className}>
                        <ModalHeader toggle={toggle}><h3 className="mt-0">{content.title}</h3></ModalHeader>
                        <ModalBody>
                            <h4 className="description mt-0">{content.description}</h4>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" size="sm" onClick={toggle}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </Row>
            </Container>
        </div>
    );
}
export default LimehillsServices;
