import React from "react";
import LimehillsNavbar from "components/Navbars/LimehillsNavbar.js";
// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

const TermsPage = () => {
    return (
        <>
            <LimehillsNavbar />
            <div className="section text-center limehills-Terms">
                <Container >
                    <Row>
                        <Col lg="5" sm="12" style={{
                            backgroundImage: "url(" + require("assets/img/limehills/about-image.png") + ")",
                            backgroundRepeat: "no-repeat",
                        }}
                            className="intro-image">
                        </Col>
                        <Col className="text-left ml-auto mr-auto" md="7" sm="12">
                            <h1 className="title">Limehills Terms</h1>
                            <h5 className="description">Company Terms</h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default TermsPage;