import React from "react";
import LimehillsNavbar from "components/Navbars/LimehillsNavbar.js";
// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

const PrivacyPage = () => {
    return (
        <>
            <LimehillsNavbar />
            <div className="section text-center limehills-privacy">
                <Container >
                    <Row>
                        <Col lg="5" sm="12" style={{
                            backgroundImage: "url(" + require("assets/img/limehills/about-image.png") + ")",
                            backgroundRepeat: "no-repeat",
                        }}
                            className="intro-image">
                        </Col>
                        <Col className="text-left ml-auto mr-auto" md="7" sm="12">
                            <h1 className="title">Limehills Data Policy</h1>
                            <h5 className="description">We give you the power to share as part of our mission to make the world more open and connected. This policy describes what information we collect and how it is used and shared. You can find additional tools and information at Privacy Basics.</h5>
                            <h5 className="description">As you review our policy, keep in mind that it applies to all Limehills brands, products and services that do not have a separate privacy policy or that link to this policy, which we call the “Limehills Services” or “Services.”</h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default PrivacyPage;