import React, { Component } from "react";
import nodemailer from "nodemailer";
import AWS from "aws-sdk";
import Recaptcha from "react-recaptcha";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroup,
  Form,
  InputGroupText,
  Input,
  Spinner,
} from "reactstrap";


class LimehillsContact extends Component {

  state = {
    loader: false, nameError: false,
    recaptchaError: false, emailError: false, messageError: false, recaptcha: false, isValid: null
  };

  onloadCallback() {
    console.log('Recaptcha loaded.');
  }

  verifyCallback = (key) => {
    if (key) {
      this.setState({ recaptcha: true, recaptchaError: false });
    }
  };

  onSubmitHandler = (event) => {
    event.preventDefault();

    // Spinner
    this.setState({ loader: true, isValid: true });

    //Name is Empty
    if (!event.target[0].value) {
      this.setState({ nameError: true, isValid: false });
    } else {
      this.setState({ nameError: false });
    }

    //Email is Empty
    if (!event.target[1].value) {
      this.setState({ emailError: true, isValid: false });
    } else {
      this.setState({ emailError: false });
    }

    //Email is not valid email 
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target[1].value)) {
      this.setState({ emailError: true, isValid: false });
    } else {
      this.setState({ emailError: false });
    }

    //Message is Empty
    if (!event.target[2].value) {
      this.setState({ messageError: true, isValid: false });
    } else {
      this.setState({ messageError: false });
    }

    //Recaptcha is Valid
    if (!this.state.recaptcha) {
      this.setState({ recaptchaError: true });
    } else {
      this.setState({ recaptchaError: false });
    }

    if (this.state.isValid && this.state.recaptcha) {
      AWS.config = new AWS.Config();
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION
      });

      // create Nodemailer SES transporter
      let transporter = nodemailer.createTransport({
        SES: new AWS.SES({
          apiVersion: '2010-12-01'
        })
      });

      // send some mail
      transporter.sendMail({
        from: 'bryan@limehills.net',
        to: 'bryan@limehills.net',
        subject: 'Message',
        text: `Email: ${event.target[1].value} \nDescription: ${event.target[2].value}
        `
      });
      event.target.reset();
      this.recaptchaInstance.reset();
      this.setState({ isValid: null });
    }
    this.setState({ loader: false });
  };

  render() {
    return (
      <div className="section landing-section">
        <Container>
          <Row>
            <Col lg="6" sm="12" style={{
              backgroundImage:
                "url(" + require("assets/img/limehills/contactus.png") + ")",
              backgroundRepeat: "no-repeat",
            }}
              className="contactus">
            </Col>
            <Col lg="6" sm="12">
              <h1 className="text-center">Keep in touch?</h1>
              <Form className="contact-form" onSubmit={(event) => { this.onSubmitHandler(event); }}>
                <Row>
                  <Col md="6">
                    <label>Name</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Name" type="text" className={this.state.nameError ? "is-invalid" : ""} />
                    </InputGroup>

                  </Col>
                  <Col md="6">
                    <label>Email</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" type="text" className={this.state.emailError ? "is-invalid" : ""} />
                    </InputGroup>
                  </Col>
                </Row>
                <label>Message</label>
                <Input
                  placeholder="Tell us more about your project."
                  type="textarea"
                  rows="4"
                  className={this.state.messageError ? "is-invalid" : ""}
                />
                <Row>
                  <Col className="mt-3" sm="7">
                    <Recaptcha
                      className={this.state.recaptchaError ? 'recaptcha-error' : ''}
                      ref={e => (this.recaptchaInstance = e)}
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                      render='explicit'
                      theme='light'
                      onloadCallback={this.onloadCallback}
                      verifyCallback={this.verifyCallback}
                    />
                  </Col>
                  <Col className="" sm="5">
                    <Button
                      className="btn-fill btn-round" color="primary" size="lg" block >
                      {this.state.loader ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Send Message'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default LimehillsContact;