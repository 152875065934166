import React from "react";
import {scroller} from "react-scroll";

// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";


function LimehillsCallToAction() {

  const scrollToContact = () => {
    scroller.scrollTo('contact', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }
    return (
        <div className="section text-center limehills-banner" style={{ backgroundImage: "url(" + require("assets/img/limehills/limehills-con.png") + ")" }}>
          <Container fluid>
            <Row>
              <Col></Col>
              <Col className="ml-auto mr-auto" md="7">
                <h1 className="title">Do you have a <br />project in mind?</h1>
                <br />
                <Button 
                  className="btn-round btn-fill"
                  color="neutral"
                  size="lg"
                  to="/"
                  onClick={(e) => scrollToContact() }
                >
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
    )
}
export default LimehillsCallToAction;










