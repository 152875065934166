import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

function LimehillsIntro() {
    return (
        <div className="section text-center limehills-services">
            <Container >
                <Row>
                    <Col lg="5" sm="12" style={{
                        backgroundImage: "url(" + require("assets/img/limehills/about-image.png") + ")",
                        backgroundRepeat: "no-repeat",
                    }}
                        className="intro-image">
                    </Col>
                    <Col className="text-left ml-auto mr-auto" md="7" sm="12">
                        <h1 className="title">Limehills</h1>
                        <h5 className="description">Limehills is a software consulting company who specializes in software development, configurations and support services. Some of the softwares and platforms we specialize in are Software Development, DevOps Engineering and Software Consulting.</h5>
                        <h5 className="description">Our vision, as a company is to provide the best solution for the client's business that can help aid and optimize their day to day productivity.</h5>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default LimehillsIntro;
