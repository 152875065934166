/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import Particles from "react-particles-js";
import {scroller} from "react-scroll";

// core components

function LandingPageHeader() {

  const scrollToContact = () => {
    scroller.scrollTo('contact', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  let particleParam = () => {
    return {
      "particles": {
        "number": {
          "value": 40
        },
        "size": {
          "value": 2,
          "speed": 40
        },
        "color": {
          "value": "#3b48af"
        },
        "line_linked": {
          "enable": false,
          "distance": 150,
          "color": "#3b48af",
          "opacity": 0.5,
          "width": .2
        },
      },
      "interactivity": {
        "events": {
          "onhover": {
            "enable": true,
            "mode": "repulse"
          }
        }
      }
    };
  }

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/limehills/bg3.png") + ")",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Particles params={particleParam()} />
        <Container>
          <Row>
            <Col lg={4} sm={12}>
              <div className="motto text-left">
                <h1>Managing <br />
                  <span style={{ fontSize: "42px", fontWeight: "bold", textTransform: "uppercase" }}>applications</span><br />
              should not be a <br />
                  <span style={{ fontSize: "42px", fontWeight: "bold", textTransform: "uppercase" }}>rocket science!</span>
                </h1>
                <br />
                <br />
                <Button className="btn-round" color="neutral" type="button" size="lg" onClick={(e) => scrollToContact() }>
                  Contact Us
              </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
